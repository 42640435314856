import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'

import { Parallax } from 'react-scroll-parallax'
import * as styles from './CateringThankYou.module.scss'

const CateringThankYou = () => {
  const data = useStaticQuery(graphql`
    {
      nhi: file(relativePath: { eq: "catering-nhi.jpeg" }) {
        childImageSharp {
          gatsbyImageData(width: 700, quality: 60, layout: CONSTRAINED)
        }
      }
      dumplings: file(relativePath: { eq: "catering-dumplings.jpeg" }) {
        childImageSharp {
          gatsbyImageData(width: 700, quality: 60, layout: CONSTRAINED)
        }
      }
      wingsBmi: file(relativePath: { eq: "catering-wings-banhmi.jpeg" }) {
        childImageSharp {
          gatsbyImageData(width: 700, quality: 60, layout: CONSTRAINED)
        }
      }
      menuParallax: file(relativePath: { eq: "menu_parallax.png" }) {
        childImageSharp {
          gatsbyImageData(width: 580, quality: 30, layout: CONSTRAINED)
        }
      }
    }
  `)

  return (
    <div className={styles.container}>
      <GatsbyImage
        image={data.menuParallax.childImageSharp.gatsbyImageData}
        style={{ width: '50%' }}
      />
      <Parallax
        translateX={['80px', '40px']}
        translateY={['-50px', '50px']}
        tagOuter="figure"
      >
        <GatsbyImage
          image={data.dumplings.childImageSharp.gatsbyImageData}
          style={{ width: '50%' }}
        />
      </Parallax>
      <div>Thank you!</div>
      <Parallax
        translateX={['-40px', '40px']}
        translateY={['-50px', '50px']}
        tagOuter="figure"
      >
        <GatsbyImage
          image={data.wingsBmi.childImageSharp.gatsbyImageData}
          style={{ width: '50%' }}
        />
      </Parallax>
      <Parallax
        translateX={['100px', '60px']}
        translateY={['-50px', '50px']}
        tagOuter="figure"
      >
        <GatsbyImage
          image={data.nhi.childImageSharp.gatsbyImageData}
          style={{ width: '70%' }}
        />
      </Parallax>
      {/* <div>hello</div> */}
    </div>
  )
}

export default CateringThankYou
